@font-face {
    font-family: 'Tickerbit Regular';
    font-weight: 500;
    src: local('VisbyCF'), url(../fonts/Tickerbit-regular.otf) format('opentype');
}

@font-face {
    font-family: "BestTen" ;
    src: local('VisbyCF'), url(../fonts/BestTen-DOT.otf) format('opentype');
}

.loading-text {
    color: #FFFFFF;
    font-family: 'Tickerbit Regular';
    font-size: 22px;
    text-align: center;
    white-space: pre-line;
    /* Add more styles as needed */
  }

.loading-progress {
    color: #808080;
    font-family: 'Tickerbit Regular';
    font-size: 12px;
    text-align: center;
    white-space: pre-line;
}

.footnote-text {
    font-family: 'BestTen';
    color: #FFFFFF;
    // background-color: #555555;
    // height:  3vh;
    // font-size: 1rem;
}