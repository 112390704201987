.contactUs {
    background-image: url("../img/home/section1Bg.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    min-height: 100vh;
    min-height: 100vh;
    position: absolute;
    top: 0;

    @media screen and (max-width:991px) {
        background-image: url("../img/home/section1Bg-Mobile.webp");
        background-size: 110% 120%;
        background-position: center center;
    }

    .header-section {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .iconGif {
        width: 70%;

        @media screen and (max-width: 991px) {
            width: auto;
        }
    }

    .header-title-section {
        display: flex;
        justify-content: start;
        align-items: center;

        @media screen and (max-width: 991px) {
            justify-content: center;
        }

        .small-square {
            width: 1.2vw;
            height: 1.2vw;
            background: #F51F99;

            @media screen and (max-width: 991px) {
                width: 20px;
                height: 20px;
            }

            @media screen and (max-width: 667px) {
                width: 15px;
                height: 15px;
            }

            @media screen and (max-width: 300px) {
                width: 15px;
                height: 15px;
            }
        }

        .header-title-text {
            color: #F51F99;
            font-family: 'Cyber Soulja';
            font-size: 4vw;
            font-style: normal;
            font-weight: 400;
            line-height: 97.5%;

            @media screen and (max-width: 991px) {
                font-size: 70px;
            }

            @media screen and (max-width: 667px) {
                font-size: 40px;
            }

            @media screen and (max-width: 300px) {
                font-size: 25px;
            }
        }
    }

    .header-small-title {
        color: #FFF;
        font-family: 'ShareTechMono';
        font-size: 2vw;
        font-style: normal;
        font-weight: 400;
        line-height: 123.5%;
        text-align: left;

        @media screen and (max-width: 991px) {
            text-align: center;
            font-size: 40px;
        }

        @media screen and (max-width: 667px) {
            text-align: center;
            font-size: 25px;
        }

        @media screen and (max-width: 300px) {
            text-align: center;
            font-size: 22px;
        }
    }

    .header-desc {
        color: #FFF;
        text-align: justify;
        font-family: 'Poppins-Regular';
        font-size: 1vw;
        font-style: normal;
        font-weight: 500;
        line-height: 157.5%;

        @media screen and (max-width: 991px) {
            font-size: 18px;
            text-align: justify;
        }

        @media screen and (max-width: 667px) {
            font-size: 14px;
            text-align: justify;
        }

        @media screen and (max-width: 300px) {
            font-size: 13px;
            text-align: justify;
        }
    }

    .reachUsSection {
        min-height: 100vh;

        .inputBoxBg {
            background-image: url(../img/reachUs/inputSectionBg.webp);
            background-size: 100% 100%;
            min-height: 70vh;

            @media screen and (max-width: 991px) {
                min-height: auto;
                background: none;
            }

            @media screen and (max-width: 667px) {
                background: none;
            }
        }

        .headerPadding {
            padding: 10vh 0 4vh 0;

            @media screen and (max-width: 991px) {
                padding: 8vh 0 4vh 0;
            }
        }

        .inputBoxTitle {
            color: #FFF;
            text-align: center;
            font-family: 'Cyber Soulja';
            font-size: 3.8vw;
            font-style: normal;
            font-weight: 400;
            line-height: 97.5%;

            @media screen and (max-width: 991px) {
                font-size: 55px;
            }

            @media screen and (max-width: 667px) {
                font-size: 40px;
            }

            @media screen and (max-width: 300px) {
                font-size: 30px;
            }
        }

        .border-bottom-white {
            width: 100%;
            border-bottom: 3px solid #fff;
        }

        .contactUs-textBox {
            border: 4px solid #FFF;
            background: #090B24;
            border-radius: 0;
            color: #747474;
            font-family: 'Tickerbit Regular';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: 117%;
            padding: 1.5vh 1vh;

            @media screen and (max-width: 991px) {
                font-size: 20px;
            }

            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }

        .contactUs-textArea {
            border: 4px solid #FFF;
            background: #090B24;
            border-radius: 0;
            color: #747474;
            min-height: 25vh !important;
            font-size: 18px !important;
            padding-left: 15px !important;
            padding-right: 15px !important;
            font-family: 'Tickerbit Regular';
            font-size: 1vw !important;
            font-style: normal;
            font-weight: 400;
            line-height: 117%;

            @media screen and (max-width: 991px) {
                font-size: 20px !important;
                min-height: 150px !important;
            }

            @media screen and (max-width: 767px) {
                font-size: 14px !important;
            }
        }

        .sentBtn {
            background-color: #fff;
            padding: 2vh 0;

            @media screen and (max-width: 991px) {
                padding: 2vh 0;
            }

            span {
                color: #000;
                text-align: center;
                font-family: 'Cyber Soulja';
                font-size: 2.5vw;
                font-style: normal;
                font-weight: 400;
                line-height: 97.5%;

                @media screen and (max-width: 991px) {
                    font-size: 50px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 40px;
                }

                @media screen and (max-width: 300px) {
                    font-size: 30px;
                }
            }
        }
    }
}