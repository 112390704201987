.menu {
    width: 100%;
    transform: translateX(100%);
    min-height: 100vh;
    background-color: #090B24;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11), 0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);
    transition: 1.5s;

    @media screen and (max-width: 992px) {
        width: 100%;
        transition: 1s;
    }

    #menuPageComponent {
        opacity: 0;
        transition: opacity 1s;
    }

    .menuTitle {
        color: #FFF;
        text-align: center;
        font-family: 'Cyber Soulja';
        font-size: 3vw;
        font-style: normal;
        font-weight: 400;
        line-height: 97.5%;

        @media screen and (max-width: 991px) {
            font-size: 70px;
        }

        @media screen and (max-width: 667px) {
            font-size: 50px;
        }

        @media screen and (max-width: 300px) {
            font-size: 40px;
        }
    }

    .menuBtnDiv {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 20vw;
        padding: 1.8vw 0;

        @media screen and (max-width: 991px) {
            width: 350px;
            padding: 3vh 0;
        }

        @media screen and (max-width: 667px) {
            width: 270px;
        }

        @media screen and (max-width: 300px) {
            width: 240px;
        }

        .menuBtnText {
            color: #090B24;
            text-align: center;
            font-family: 'Cyber Soulja';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 400;
            line-height: 97.5%;

            @media screen and (max-width: 991px) {
                font-size: 35px;
            }

            @media screen and (max-width: 667px) {
                font-size: 25px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }
    }

    #showcaseBtn {
        background-image: url(../img/showcaseBtn.webp);
    }

    #purposeBtn {
        background-image: url(../img/purposeBtn.webp);
    }

    #articlesBtn {
        background-image: url(../img/articlesBtn.webp);
    }

    .socialMediaSize {
        width: 2vw;
        aspect-ratio: 1/1;
        object-fit: contain;

        @media screen and (max-width: 991px) {
            width: 40px;
        }

        @media screen and (max-width: 667px) {
            width: 35px;
        }

        @media screen and (max-width: 300px) {
            width: 25px;
        }
    }

    .closeBtnSize {
        width: 4vw;
        cursor: pointer;

        @media screen and (max-width: 991px) {
            width: 80px;
        }

        @media screen and (max-width: 667px) {
            width: 60px;
        }

        @media screen and (max-width: 300px) {
            width: 50px;
        }
    }

    #reachUsBtn {
        background-image: url(../img/reachUsBtn.webp);
    }

    .menuRow {
        .menuArrow {
            display: none;
            width: 3vw;
        }

        &:hover {
            .menuArrow {
                display: block;
                position: absolute;
                top: 10%;
                left: calc(50% - 30%);
            }
        }
    }

    .arrowPush {
        animation-name: arrowPushAnim;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        margin-top: 5px;
    }


    @keyframes arrowPushAnim {
        0% {
            transform: translate(0px, 0px);
        }

        50% {
            transform: translate(-10px, 0px);
        }

        100% {
            transform: translate(0px, 0px);
        }
    }
}

.logo-width {
    width: 12vw;
    margin: 1vw 0 0 2vw;

    @media screen and (max-width: 991px) {
        width: auto;
    }
}