:root {
    --primary-color-1: #0C62F7;
    --text-primary: #0C62F7;
    --text-error: #C10808;
    --menu-active-text-color: #0C62F7;
    --menu-inactive-text-color: #696E80;
    --menu-small-title: #A3ADC1;
    --page-title: #0F2040;
    --card-bg: #FCFCFC;
    --text-visible: #0F2040;
    --text-invisible: #A3ADC1;
}

@font-face {
    font-family: 'Tickerbit Italic';
    src: local('VisbyCF'), url(../fonts/Tickerbit-italic.otf) format('opentype');
}

@font-face {
    font-family: 'Tickerbit Mono';
    font-weight: 100;
    src: local('VisbyCF'), url(../fonts/Tickerbit-mono.otf) format('opentype');
}

@font-face {
    font-family: 'Tickerbit Regular';
    font-weight: 500;
    src: local('VisbyCF'), url(../fonts/Tickerbit-regular.otf) format('opentype');
}

@font-face {
    font-family: 'Tickerbit MonoItalic';
    font-weight: 600;
    src: local('VisbyCF'), url(../fonts/Tickerpixel-monoitalic.otf) format('opentype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('~assets/fonts/Poppins-Bold.ttf');
    src: url('~assets/fonts/Poppins-Bold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('~assets/fonts/Poppins-Light.ttf');
    src: url('~assets/fonts/Poppins-Light.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('~assets/fonts/Poppins-Medium.ttf');
    src: url('~assets/fonts/Poppins-Medium.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('~assets/fonts/Poppins-Regular.ttf');
    src: url('~assets/fonts/Poppins-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('~assets/fonts/Poppins-SemiBold.ttf');
    src: url('~assets/fonts/Poppins-SemiBold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cyber Soulja';
    src: url('~assets/fonts/Cyber Soulja.otf');
    src: url('~assets/fonts/Cyber Soulja.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ShareTechMono';
    src: url('~assets/fonts/ShareTechMono-Regular.ttf');
    src: url('~assets/fonts/ShareTechMono-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Poppins-Regular' !important;
    font-style: normal;
    line-height: 1 !important;
    touch-action: pan-x pan-y;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}


.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

.font-8 {
    line-height: 105%;
    font-size: 0.5em;
}

.font-10 {
    font-size: 0.625em;
}

.font-12 {
    font-size: 0.75em;
}

.font-14 {
    font-size: 0.875em;
}

.font-16 {
    font-size: 1em;
}

.font-18 {
    font-size: 1.125em;
}

.font-19 {
    font-size: 1.1875em;
}

.font-20 {
    font-size: 1.25em;
}

.font-22 {
    font-size: 1.375em;
}

.font-24 {
    font-size: 1.5em;
}

.font-25 {
    font-size: 1.5625em;
}

.font-26 {
    font-size: 1.625em;
}

.font-28 {
    font-size: 1.75em;
}

.font-30 {
    font-size: 1.875em;
}

.font-32 {
    font-size: 2em;
}

.font-34 {
    font-size: 2.125em;
}

.font-35 {
    font-size: 2.1875em;
}

.font-40 {
    font-size: 2.5em;
}

.font-100 {
    font-size: 6.25em;
}

.letter-spacing-18px {
    letter-spacing: 1.125em
}

.line-height-145percent {
    line-height: 145.5%;
}

.line-height-100percent {
    line-height: 100.5%;
}

.line-height-120percent {
    line-height: 120%;
}

.text-primary-color {
    color: var(--text-primary);
}

.text-menu-active-text-color {
    color: var(--menu-active-text-color);
}

.text-menu-inactive-text-color {
    color: var(--menu-inactive-text-color);
}

.text-page-title {
    color: var(--page-title);
}

.text-visible {
    color: #0F2040;
}

.text-invisible {
    color: #A3ADC1;
}

a {
    text-decoration: none !important;
}

.min-height-100 {
    min-height: 100vh;
}

.decoSize {
    width: 15vw;

    @media screen and (max-width:991px) {
        width: auto;
    }

    @media screen and (max-width:667px) {
        width: 50%;
    }
}