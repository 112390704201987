.section1 {
  background: transparent;
  min-height: 100vh;
  position: absolute;
  top: 0;
}

.section1Bg {
  background-image: url("../img/home/section1Bg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  background-attachment: fixed;
  transition: 2s;

  @media screen and (max-width:991px) {
    background-attachment: fixed;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.slick-slide {
  transform: scale(0.5);
  transition: 1s;

  @media screen and (max-width:991px) {
    transform: scale(0.8);
  }

  @media screen and (max-width:667px) {
    transform: scale(0.8);
  }

  .view-btn {
    display: none;
  }
}

.slick-current {
  transform: scale(0.8);
  transition: 1s;
  margin: auto;

  @media screen and (max-width:991px) {
    transform: scale(1);
  }

  .view-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid #FFF;
  }
}


.gameBoy {
  padding-top: 15vh;

  @media screen and (max-width:767px) {
    padding-top: 18vh;
  }

  @media screen and (max-width:300px) {
    padding-top: 25vh;
  }
}

.gameTitle-position {
  position: absolute;
  top: 13%;
  left: 0;
  right: 0;
  margin: auto;

  @media screen and (max-width:767px) {
    top: 11%;
  }
}


.gameImg-position {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 28.5%;
  border-radius: 15px;
  max-width: 350px;
  height: 300px;
  // opacity: 0;

  @media screen and (max-width:767px) {
    top: 12%;
    width: 75%;
    aspect-ratio: 1/1;
    object-fit: contain;
  }

  @media screen and (max-width:300px) {
    top: -15%;
    width: 75%;
    aspect-ratio: 1/1;
    object-fit: contain;
  }
}

#expandGameDiv {
  opacity: 0;
}

.navbar-icon {
  div {
    display: none !important;
  }
}

.expandGameImg {
  transition: 2s;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 37vh;
  border-radius: 15px;
  width: 350px;
  height: 240px;

  @media screen and (max-width:991px) {
    top: 31%;
    width: 350px;
    height: 300px;
  }

  @media screen and (max-width:767px) {
    top: 35vh;
    width: 210px;
    height: 200px;
  }

  @media screen and (max-width:767px) {
    top: 41%;
    width: 140px;
    height: 120px;
  }
}

.game-title {
  fill: #FFF;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-size: 23px;
  font-family: 'Tickerbit Regular';

  @media screen and (max-width:767px) {
    font-size: 14px;
  }
}

.view-txt {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins-Regular';
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 97.5%;
  /* 24.375px */

  @media screen and (max-width:767px) {
    font-size: 18px;
  }

  @media screen and (max-width:300px) {
    font-size: 13px;
  }

}

@keyframes growIn {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
    top: 0%;
  }
}

#slider {
  transform: scale(1);
  transition: 1s;
}

.grow-up {
  animation: growIn 1s forwards;
  // scale:3.1;
  border-radius: 30px;
}

.detail {
  position: fixed;
  top: 0%;
  left: 0%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
  background: transparent;
  // background-image: url("../img/home/section1Bg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;
}

.gameDiv {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;

}

.small-square {
  width: 25px;
  height: 25px;
  background: #fff;

  @media screen and (max-width: 991px) {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 667px) {
    width: 15px;
    height: 15px;
  }

  @media screen and (max-width: 300px) {
    width: 15px;
    height: 15px;
  }
}

.game-desc-title {
  font-family: 'Cyber Soulja';
  font-size: 30px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;

}

.game-desc {
  font-family: "Poppins-Regular";
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;

  @media screen and (max-width: 667px) {
    font-size: 15px;
    line-height: 25px;
  }

  @media screen and (max-width: 300px) {
    font-size: 15px;
    line-height: 25px;
  }
}

.line {
  background: white;
}

.card-game-spec {

  border: 1px solid #FFFFFF;

  &.border-left {
    border-radius: 30px 0px 0px 30px;
  }

  &.noBorder {
    border-radius: 0px;
  }

  &.border-right {
    border-radius: 0px 30px 30px 0px;
  }

  &.border {
    border-radius: 30px;
  }

  @media screen and (max-width:667px) {
    border-radius: 25px;
    border: 1px solid #FFF;
  }
}

.card-game-spec-title {
  font-family: 'Tickerbit Regular';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #838383;

  @media screen and (max-width:667px) {
    font-size: 15px;
  }
}

.card-game-spec-value {
  font-family: 'Poppins-Regular';
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: #FFFFFF;

  @media screen and (max-width:667px) {
    font-size: 17px;
  }
}

.game-intro-value {
  color: #FFF;
  text-align: justify;
  font-family: 'Poppins-Regular';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 157.5%;

  @media screen and (max-width: 991px) {
    font-size: 20px;
  }

  @media screen and (max-width: 667px) {
    font-size: 15px;
  }

  @media screen and (max-width: 300px) {
    font-size: 13px;
  }

}

.sticky-div {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  /* Adjust this value to set the sticky position */
}

.game-intro-title {
  font-family: 'Tickerbit Regular';
  font-size: 28px;
  font-weight: 400;
  line-height: 49px;
  letter-spacing: 0em;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
  text-align: center;

  @media screen and (max-width: 991px) {
    font-size: 28px;
  }

  @media screen and (max-width: 667px) {
    font-size: 22px;
  }

  @media screen and (max-width: 300px) {
    font-size: 18px;
  }

  &.red {
    color: #F51F99;
  }

  &.green {
    color: #9DE765;
  }

  &.blue {
    color: #0098DC;
  }

  @media screen and (max-width:667px) {
    transform: rotate(0);
    writing-mode: unset;
  }
}

.rotate {
  display: inline-block;
}

.menu-bar {
  width: 170px;

  @media screen and (max-width:667px) {
    width: 100px;
  }
}

.menuBtnBg {
  background-image: url(../../assets/img/menuBar.webp);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
  width: fit-content;
  float: right;
  margin-right: 3%;
  cursor: pointer;
  padding: 0.9vw 3vw;
  margin: 1vw 2vw 0 0;

  @media screen and (max-width: 991px) {
    padding: 10px 40px;
  }

  @media screen and (max-width: 667px) {
    padding: 7px 30px;
  }
}

.menu-text {
  font-family: 'Tickerbit Regular';
  font-size: 1.2vw;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;

  @media screen and (max-width:991px) {
    font-size: 25px;
  }

  @media screen and (max-width:667px) {
    top: 2%;
    right: 18%;
    font-size: 16px;
  }

}

.slick-dots li button:before {
  color: white !important;
}

#gameDetails {
  opacity: 0;
  transition: 1s;
}

.backBtnSize {
  @media screen and (max-width: 991px) {
    width: 50px;
  }

  @media screen and (max-width: 667px) {
    width: 35px;
  }

  @media screen and (max-width: 300px) {
    width: 25px;
  }
}

.robotGifSize {
  width: 4vw;

  @media screen and (max-width: 991px) {
    width: 80px;
  }

  @media screen and (max-width: 667px) {
    width: 50px;
  }
}

.headerGif {
  width: 50%;

  @media screen and (max-width: 991px) {
    width: 60%;
  }

  @media screen and (max-width: 767px) {
    width: 80%;
  }
}

.copywriteText {
  font-size: 0.8vw;

  @media screen and (max-width: 991px) {
    font-size: 15px;
  }

  @media screen and (max-width: 667px) {
    font-size: 15px;
  }
}


.demo-1 {
  --color-text: #cdbcbc;
  --color-bg: #000;
  --color-link: #fff;
  --color-link-hover: #fff;
  --cursor-bg: #fbd864;
  --cursor-blend-mode: exclusion;
  --gradient-text-1: #e8c942;
  --gradient-text-2: rgb(148 98 209);
}

.cursor {
  height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 99;
  mix-blend-mode: var(--cursor-blend-mode);
  --size: calc(100vw / var(--columns));
}

.cursor__inner {
  display: grid;
  grid-template-columns: repeat(var(--columns), var(--size));
}

.cursor__inner-box {
  width: var(--size);
  height: var(--size);
  background: var(--cursor-bg);
  opacity: 0;
  border-radius: var(--cursor-radius);
}

@media screen and (min-width: 53em) {
  :root {
    --columns: 30;
    --page-padding: 2rem;
  }

  .frame {
    grid-template-columns: auto auto auto 1fr;
    grid-template-areas: 'title prev sponsor demos';
    justify-items: start;
    grid-gap: 2rem;
  }

  .frame__demos {
    justify-self: end;
  }

  .frame__demos-item:first-child {
    width: auto;
  }

  .content {
    align-items: center;
  }
}

.loadingPage {
  background-color: #0a0b25;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

}

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  background: var(--c) 50% 0,
    var(--c) 50% 100%,
    var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 13.4px 13.4px;
  background-repeat: no-repeat;
  animation: spinner-3hs4a3 1s infinite;
}

.spinner::before {
  --c: radial-gradient(farthest-side, #F51F99 92%, #0000);
  margin: 4.5px;
  background-size: 9px 9px;
  animation-timing-function: linear;
}

.spinner::after {
  --c: radial-gradient(farthest-side, #F51F99 92%, #0000);
}

@keyframes spinner-3hs4a3 {
  100% {
    transform: rotate(.5turn);
  }
}