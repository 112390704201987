.news {
    background-image: url("../img/home/section1Bg.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    min-height: 100vh;
    min-height: 100vh;
    position: absolute;
    top: 0;

    @media screen and (max-width:991px) {
        background-image: url("../img/home/section1Bg-Mobile.webp");
        background-size: 100% 100%;
        background-position: center center;
    }

    .header-section {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .newsGif {
        width: 70%;

        @media screen and (max-width: 991px) {
            width: auto;
        }
    }

    .header-title-section {
        display: flex;
        justify-content: start;
        align-items: center;

        @media screen and (max-width: 991px) {
            justify-content: center;
        }

        .small-square {
            width: 1.2vw;
            height: 1.2vw;
            background: #F51F99;

            @media screen and (max-width: 991px) {
                width: 20px;
                height: 20px;
            }

            @media screen and (max-width: 667px) {
                width: 15px;
                height: 15px;
            }

            @media screen and (max-width: 300px) {
                width: 15px;
                height: 15px;
            }
        }

        .header-title-text {
            color: #F51F99;
            font-family: 'Cyber Soulja';
            font-size: 4vw;
            font-style: normal;
            font-weight: 400;
            line-height: 97.5%;

            @media screen and (max-width: 991px) {
                font-size: 70px;
            }

            @media screen and (max-width: 667px) {
                font-size: 50px;
            }

            @media screen and (max-width: 300px) {
                font-size: 30px;
            }
        }
    }

    .header-small-title {
        color: #FFF;
        font-family: 'ShareTechMono';
        font-size: 2vw;
        font-style: normal;
        font-weight: 400;
        line-height: 123.5%;
        text-align: left;

        @media screen and (max-width: 991px) {
            text-align: center;
            font-size: 40px;
        }

        @media screen and (max-width: 667px) {
            text-align: center;
            font-size: 25px;
        }

        @media screen and (max-width: 300px) {
            text-align: center;
            font-size: 22px;
        }
    }

    .header-desc {
        color: #FFF;
        text-align: justify;
        font-family: 'Poppins-Regular';
        font-size: 1vw;
        font-style: normal;
        font-weight: 500;
        line-height: 157.5%;

        @media screen and (max-width: 991px) {
            font-size: 18px;
            text-align: justify;
        }

        @media screen and (max-width: 667px) {
            font-size: 14px;
            text-align: justify;
        }

        @media screen and (max-width: 300px) {
            font-size: 13px;
            text-align: justify;
        }
    }

    .newsSection {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 991px) {
            min-height: auto;
        }

        .newsImg {
            border-radius: 25px;
            border: 2px solid #fff;
        }

        .stepImg {
            width: 120px;
            aspect-ratio: 1/1;
            object-fit: contain;
        }

        .dateNewsText {
            color: #4E5499;
            font-family: 'Poppins-Regular';
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: 157.5%;
        }

        .border-bottom-white {
            border-bottom: 1px solid #4E5499;
        }

        .stepTitle {
            color: #FFF;
            font-family: 'Tickerbit Regular';
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 117%;
            text-transform: uppercase;
        }

        .readMoreBtn {
            background-image: url(../img/news/readMoreBtn.webp);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            text-align: center;
            cursor: pointer;

            span {
                color: #FFF;
                font-family: 'Tickerbit Regular';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 117%;
                text-transform: uppercase;

                @media screen and (max-width: 991px) {
                    font-size: 18px;
                    text-align: justify;
                }

                @media screen and (max-width: 667px) {
                    font-size: 14px;
                    text-align: justify;
                }

                @media screen and (max-width: 300px) {
                    font-size: 13px;
                    text-align: justify;
                }
            }

            &:hover {
                background-image: url(../img/news/readMoreBtnHover.webp);
                transition: 1s;

                span {
                    color: #000;
                }
            }
        }
    }

    .stepDesc {
        color: #FFF;
        font-family: 'Poppins-Regular';
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 157.5%;

        @media screen and (max-width: 991px) {
            font-size: 17px;
        }

        @media screen and (max-width: 667px) {
            font-size: 14px;
        }

        @media screen and (max-width: 300px) {
            font-size: 13px;
        }
    }

    .stepDivider {
        color: #FFF;
        font-family: 'Tickerbit Regular';
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 50%;
        letter-spacing: 0.4rem;
    }


    .header-banner {
        width: 100%;
    }

    .newsBanner {
        height: 55vh;
        object-fit: cover;
        object-position: center;
        border-radius: 0px 0px 160px 160px;

        @media screen and (max-width: 991px) {
            border-radius: 0px 0px 60px 60px;
        }
    }

    .header-banner-title {
        position: absolute;
        top: 30%;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;

        @media screen and (max-width: 991px) {
            top: 40%;
        }

        span {
            color: #FFF;
            text-align: center;
            font-family: 'Cyber Soulja';
            font-size: 113px;
            font-style: normal;
            font-weight: 400;
            line-height: 97%;

            @media screen and (max-width: 991px) {
                font-size: 70px;
                text-align: justify;
            }

            @media screen and (max-width: 667px) {
                font-size: 40px;
                text-align: justify;
            }

            @media screen and (max-width: 300px) {
                font-size: 30px;
                text-align: justify;
            }
        }
    }

    .contentTitle {
        color: #F51F99;
        text-align: center;
        font-family: 'ShareTechMono';
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 123.5%;

        @media screen and (max-width: 991px) {
            font-size: 35px;
        }

        @media screen and (max-width: 667px) {
            font-size: 25px;
        }

        @media screen and (max-width: 300px) {
            font-size: 20px;
        }
    }

    .smallTitle {
        color: #9DE765;
        font-family: 'Tickerbit Regular';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 157.5%;

        @media screen and (max-width: 300px) {
            font-size: 15px;
        }
    }
}