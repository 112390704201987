.purpose {
    background-image: url("../img/home/section1Bg.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    min-height: 100vh;
    min-height: 100vh;
    position: absolute;
    top: 0;

    @media screen and (max-width:991px) {
        background-image: url("../img/home/section1Bg-Mobile.webp");
        background-size: 130% 130%;
        background-position: center center;
    }

    .header-section {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-title-section {
        display: flex;
        justify-content: start;
        align-items: center;

        @media screen and (max-width: 991px) {
            justify-content: center;
        }

        .small-square {
            width: 1.2vw;
            height: 1.2vw;
            background: #F51F99;

            @media screen and (max-width: 991px) {
                width: 20px;
                height: 20px;
            }

            @media screen and (max-width: 667px) {
                width: 15px;
                height: 15px;
            }

            @media screen and (max-width: 300px) {
                width: 15px;
                height: 15px;
            }
        }

        .header-title-text {
            color: #F51F99;
            font-family: 'Cyber Soulja';
            font-size: 4vw;
            font-style: normal;
            font-weight: 400;
            line-height: 97.5%;

            @media screen and (max-width: 991px) {
                font-size: 60px;
            }

            @media screen and (max-width: 667px) {
                font-size: 40px;
            }

            @media screen and (max-width: 300px) {
                font-size: 30px;
            }
        }
    }

    .header-desc {
        color: #FFF;
        text-align: justify;
        font-family: 'Poppins-Regular';
        font-size: 1vw;
        font-style: normal;
        font-weight: 500;
        line-height: 157.5%;

        @media screen and (max-width: 991px) {
            font-size: 18px;
            text-align: justify;
        }

        @media screen and (max-width: 667px) {
            font-size: 14px;
            text-align: justify;
        }

        @media screen and (max-width: 300px) {
            font-size: 13px;
            text-align: justify;
        }
    }

    .partnerArrow {
        max-width: 15vw;

        @media screen and (max-width: 991px) {
            max-width: 300px;
            transform: rotate(90deg);
        }

        @media screen and (max-width: 667px) {
            transform: rotate(90deg);
            max-width: 200px;
        }
    }

    .stepSection {
        min-height: 100vh;
        padding: 5vh 0 0 0;

        .partnerHeaderDiv {
            position: absolute;
            top: 30%;

            @media screen and (max-width: 991px) {
                top: 20%;
            }
        }

        .stepImg {
            width: 6vw;
            aspect-ratio: 1/1;
            object-fit: contain;

            @media screen and (max-width: 991px) {
                width: 120px;
            }

            @media screen and (max-width: 667px) {
                width: 80px;
            }

            @media screen and (max-width: 300px) {
                width: 80px;
            }
        }

        .stepNumber {
            color: #9DE777;
            font-family: 'Cyber Soulja';
            font-size: 2.6vw;
            font-style: normal;
            font-weight: 400;
            line-height: 97.5%;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 40px;
            }

            @media screen and (max-width: 667px) {
                font-size: 35px;
            }

            @media screen and (max-width: 300px) {
                font-size: 30px;
            }
        }

        .stepTitle {
            color: #FFF;
            font-family: 'Tickerbit Regular';
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 400;
            line-height: 117%;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 22px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }
    }

    .partnerSectionTitle {
        color: #FFF;
        font-family: 'ShareTechMono';
        font-size: 2.5vw;
        font-style: normal;
        font-weight: 400;
        line-height: 123.5%;

        @media screen and (max-width: 991px) {
            font-size: 40px;
        }

        @media screen and (max-width: 667px) {
            font-size: 25px;
        }

        @media screen and (max-width: 300px) {
            font-size: 20px;
        }
    }

    .partnerCrossSize {
        width: 3vw;

        @media screen and (max-width: 991px) {
            width: 50px;
        }
    }

    .stepDesc {
        color: #FFF;
        font-family: 'Poppins-Regular';
        font-size: 0.9vw;
        font-style: normal;
        font-weight: 500;
        line-height: 157.5%;

        @media screen and (max-width: 991px) {
            font-size: 17px;
        }

        @media screen and (max-width: 667px) {
            font-size: 14px;
        }

        @media screen and (max-width: 300px) {
            font-size: 13px;
        }
    }

    .stepDivider {
        color: #FFF;
        font-family: 'Tickerbit Regular';
        font-size: 2vw;
        font-style: normal;
        font-weight: 400;
        line-height: 50%;
        letter-spacing: 0.4rem;

        @media screen and (max-width: 991px) {
            font-size: 25px;
        }
    }

    .diversityDiv {
        border-radius: 25px;
        border: 1px solid #FFF;
        padding: 0 2vw;
        min-height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 991px) {
            padding: 0 30px;
            min-height: 40vh;
        }

        @media screen and (max-width: 667px) {
            padding: 0 20px;
            min-height: 40vh;
        }

        @media screen and (max-width: 667px) {
            padding: 0 20px;
            min-height: 55vh;
        }

        .diversityTitle {
            color: #0098DC;
            text-align: center;
            font-family: 'Tickerbit Regular';
            font-size: 1.4vw;
            font-style: normal;
            font-weight: 400;
            line-height: 117%;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 22px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }

        .stepImg {
            width: 4vw;
            aspect-ratio: 1/1;
            object-fit: contain;
            margin-bottom: 1vw;

            @media screen and (max-width: 991px) {
                width: 120px;
            }

            @media screen and (max-width: 667px) {
                width: 80px;
            }

            @media screen and (max-width: 300px) {
                width: 60px;
            }
        }
    }
}